import React, { Component } from 'react';
//import IPFSHelper from 'helper/IPFSHelper';
import qs from 'querystring';
import ChainHelper from "helper/ChainHelper";
import Loading from "components/Loading/Loading";

// class CartItemView extends Component {
//     render() {
//         let { item, price, quantity } = this.props;
//         return (
//             <div className="shop-item">
//                 <div className="product">
//                     <div className="preview">
//                         <NFTPreview nft={item} size={"100x150"} />
//                     </div>
//                 </div>
//                 <div className="info">
//                     <div className="name">
//                         {item.name}
//                     </div>
//                     <div className="subname"><b>{ quantity }x {price}</b>{ item?.data?.contains && <> - <b>{ item?.data?.contains }</b></> }</div>
//                 </div>
//                 {/* <div className="quantity">
//                     <div className="total"></div>
//                 </div> */}
//             </div>
//         )
//     }
// }

class PaymentSuccessWAX extends Component {
    state = {
        loading: true,
        tries: 0,
        transaction_id: null,
        transaction: null,
    };

    componentDidMount () {
        let { location: { search } } = this.props;
        if (!search || search.length === 0) search = "?";
        let args = qs.parse(search.substr(1));

        if (args.transaction) {
            this.setState({
                transaction_id: args.transaction,
            }, async () => {
                await this.loadPurchase();
            });
        }
    }

    loadPurchase = async () => {
        if (!this.state.transaction) {
            try {
                let transaction = await ChainHelper.get_transaction(this.state.transaction_id);
                if (![200,204].includes(transaction.status)) {
                    if (this.state.tries < 5) {
                        this.setState({
                            tries: this.state.tries + 1,
                        });
                        setTimeout(this.loadPurchase, 5000);
                    } else {
                        this.setState({
                            loading: false,
                        });
                    }
                    return;
                }

                transaction = await transaction.json();

                if (!transaction?.id) {
                    if (this.state.tries < 5) {
                        this.setState({
                            tries: this.state.tries + 1,
                        });
                        setTimeout(this.loadPurchase, 5000);
                    } else {
                        this.setState({
                            loading: false,
                        });
                    }
                    return;
                }

                this.setState({
                    loading: false,
                    transaction: transaction
                });
            } catch (e) {
                if (this.state.tries < 5) {
                    this.setState({
                        tries: this.state.tries + 1,
                    });
                    setTimeout(this.loadPurchase, 5000);
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            }
        }
    }

    render() {
        let { props: { match: { params: { collection_name } } } } = this;
        let { transaction, transaction_id } = this.state;

        if (!transaction_id) {
            return(
                <main>
                    Error: Invalid transaction ID
                </main>
            );
        }

        return (
            <main id="PaymentSuccessWAX">
                <h1 className={"light"}>
                    Order Summary&nbsp;
                    {!this.state.loading && transaction?.id &&
                        <a
                            href={`https://wax.bloks.io/transaction/${this.state.transaction_id}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <small style={{fontSize: "1rem", fontWeight: 500}}>
                                #{this.state.transaction_id?.substr(0, 18)}
                            </small>
                        </a>
                    }
                </h1>
                <br />
                {this.state.loading &&
                    <Loading />
                }
                {!this.state.loading && !transaction?.id &&
                    <>
                        Error: Transaction could not be loaded.<br />
                        <br />
                        We couldn't load your transaction, but this may not mean it didn't go through! Check your <a href={"https://wallet.wax.io/nfts?collection_name=" + collection_name} target="_blank" rel="noopener noreferrer">wallet</a> for your item(s) in the next few minutes.<br />
                        <br />
                        <small>Transaction: <a href={"https://wax.bloks.io/transaction/" + this.state.transaction_id} target="_blank" rel="noopener noreferrer">{this.state.transaction_id}</a></small>
                    </>
                }
                {!this.state.loading && transaction?.id &&
                    <>
                        Hurray! You should receive your item(s) in the next few minutes.<br />
                        <br />
                        Go back and unpack them, view them in your <a href={"https://wallet.wax.io/nfts?collection_name=" + collection_name} target="_blank" rel="noopener noreferrer">wallet</a>, or trade them. Pretty cool, huh?<br />
                        <br />
                        <small>Transaction: <a href={"https://wax.bloks.io/transaction/" + this.state.transaction_id} target="_blank" rel="noopener noreferrer">{this.state.transaction_id}</a></small>
                    </>
                }
                <br />
                {/* <h3>Your Order:</h3>
                <div className={"shop-items noheight"}>
                    {
                        []?.map(item => {
                            let { template_id, shopItem, quantity, unit_amount } = item;
                            return (
                                <CartItemView item={shopItem.item} quantity={quantity} price={"$" + parseFloat(unit_amount).toFixed(2)} />
                            )
                        })
                    }
                </div>   */}
            </main>
        )
    }
}


export default PaymentSuccessWAX;