import {ExplorerApi} from "atomicassets";
import CacheHelper from "helper/CacheHelper";

global.config = {};
global.tos = {};
global.templates = {};
global.sales = {};
global.virls = {};
global.atomic = null;

const loadConfig = async (collection_name) => {
    console.log("Collection Name:", collection_name);

    let config = {};
    try {
        config = await fetch(global.data_url + "/" + collection_name + "/settings.json?version=" + global.sitetime).then(res => res.json());
        if (!config || !config?.name) {
            throw new Error("Error loading config");
        }
    } catch (err) {
        console.error(err.toString());
    }

    config.collection_name = collection_name;
    config.collectionName = collection_name;

    console.log("Config Loaded", config);

    await CacheHelper.loadTemplates(collection_name);
    await CacheHelper.loadSales(collection_name);
    await CacheHelper.loadTos(collection_name);

    global.config[collection_name] = config;

    return true;
}

const loadAtomic = async () => {
    let api_url = process.env.REACT_APP_ATOMIC_API_URL || "https://atomic.wax.io";

    global.atomic = new ExplorerApi(api_url, "atomicassets", {fetch});
}

const loadVirl = async (collection_name, asset_id, activeUser) => {
    console.debug("Asset:", asset_id);

    await CacheHelper.loadVirls(collection_name);

    await loadAtomic();

    let asset = await global.atomic.getAsset(asset_id);
    console.debug(asset);

    let data = {asset};

    if (asset.collection.collection_name !== collection_name) {
        data.error_code = 801;
        data.error = `Asset ${asset_id} does not belong to collection ${collection_name}.`;
        return data;
    }

    if (!asset.template) {
        data.error_code = 802;
        data.error = `Asset does not belong to a template so can not be redeemed`;
        return data;
    }

    let virl_templates = Object.keys(global.virls[collection_name].virls);
    if (!virl_templates.includes(asset.template.template_id.toString())) {
        data.error_code = 803;
        data.error = `Template ID ${asset.template.template_id} is not configured as a vIRL`;
        return data;
    }

    let virl = global.virls[collection_name].virls[asset.template.template_id];
    data.asset.virl = virl;
    if (parseInt(virl.end_ms) > 0 && parseInt(virl.end_ms) <= new Date().getTime()) {
        let cutoff = new Date(parseInt(virl.end_ms)).toLocaleString();
        data.error_code = 804;
        data.error = `Redemption of this vIRL ended on ${cutoff}`;
        return data;
    }

    if (asset.burned_at_block || asset.burned_at_time) {
        data.error_code = 805;
        data.error = `Asset ${asset_id} has already been redeemed.`;
        return data;
    }

    return data;
}

const ConfigLoader = {
    loadConfig,
    loadAtomic,
    loadVirl
}

export default ConfigLoader;
