import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "provider/AppContext";
import ConfigLoader from "helper/ConfigLoader";
import Loading from "components/Loading/Loading";
import {Button, Grid, Message} from "semantic-ui-react";
import PreviewVirl from "./PreviewVirl";
import VirlAPI from "helper/VirlAPI";
import {Redirect} from "react-router-dom";

const CompleteVirl = (props) => {
    const {wax} = useContext(AppContext);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [asset, setAsset] = useState(null);
    const [status, setStatus] = useState(VirlAPI.RedemptionState.INITIATED);
    const [redirect, setRedirect] = useState(null);

    useEffect(() => {
        loadVirl().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let { collection_name } = props;
        if (asset && !error && status !== VirlAPI.RedemptionState.COLLECTED) {
            VirlAPI.getStatus([asset.asset_id]).then((res) => {
                console.log(res);
                if (Object.keys(res).length > 0 && res[asset.asset_id]?.error) {
                    setLoading(false);
                    setError(res[asset.asset_id].error);
                    return;
                }
                if (
                  Object.keys(res).length === 0
                  || (res[asset.asset_id]?.type === 'stripe' && parseInt(res[asset.asset_id]?.status) < VirlAPI.RedemptionState.PAID)
                ) {
                    setRedirect(<Redirect to={"/" + collection_name + "/redeem/" + asset.asset_id} />);
                }
                if (parseInt(res[asset.asset_id]?.status) !== status) {
                    setStatus(parseInt(res[asset.asset_id].status));
                } else {
                    const interval = setInterval(async () => {
                        let res2 = await VirlAPI.getStatus([asset.asset_id]);
                        if (parseInt(res2[asset.asset_id]?.status) !== status) {
                            setStatus(parseInt(res2[asset.asset_id].status));
                            clearInterval(interval);
                        }
                    }, 5000);
                    return () => {
                        clearInterval(interval);
                    };
                }
            }).catch(err => {
                console.error(err);
                setStatus(VirlAPI.RedemptionState.REQUESTED);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asset]);

    const loadVirl = async () => {
        let { collection_name, asset_id } = props;
        ConfigLoader.loadVirl(collection_name, asset_id).then(asset => {
            setAsset(asset.asset);
            if (asset?.error && asset?.error_code !== 805) {
                setError(asset.error || null);
            }
        }).catch(err => {
            setError(err.toString());
        }).finally(async () => {
            setLoading(false);
        });
    }

    if (redirect) {
        return redirect;
    }

    return (
        <main id="CompleteVirl">
            {loading && !error &&
                <Loading />
            }
            {error &&
                <Message negative>
                    <p>{error}</p>
                </Message>
            }
            {!loading && !error &&
                <Grid reversed='mobile vertically'>
                    <Grid.Column className="virl-redemption" mobile={16} tablet={8} computer={8}>
                        {!wax.userAccount &&
                            <div className="logged-out">
                                <div className="section-heading">
                                    Please login to view redemption status.
                                </div>
                                <Button as={'a'} primary compact href={global.pay_url + '/login'} referrerpolicy={'unsafe-url'}>Login</Button>
                            </div>
                        }
                        {wax.userAccount &&
                            <>
                                {status === VirlAPI.RedemptionState.REQUESTED &&
                                    <section className="order-status">
                                        <h2>Not redeemed</h2>
                                        <p>This asset has not been redeemed.</p>
                                    </section>
                                }
                                {status >= VirlAPI.RedemptionState.INITIATED && status < VirlAPI.RedemptionState.COLLECTED &&
                                    <section className="order-status">
                                        <h2>Thank you for redeeming your item!</h2>
                                        <p>We have your vIRL and are processing your redemption.</p>
                                        <p>This can take up to 10 minutes, please keep this page open.</p>
                                        <Loading />
                                    </section>
                                }
                                {status === VirlAPI.RedemptionState.COLLECTED &&
                                    <section className="order-status">
                                        <h2>Thank you for redeeming your item!</h2>
                                        <p>Your vIRL redemption has been processed, details have been emailed to you.</p>
                                    </section>
                                }
                            </>
                        }
                    </Grid.Column>
                    <PreviewVirl asset={asset} />
                </Grid>
            }
        </main>
    )
}

export default CompleteVirl;
