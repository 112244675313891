import CacheHelper from "helper/CacheHelper";

let STORE_TYPE = {
    WAX: "WAX",
    CreditCard: "Credit Card",
}

const filterBlacklistedSales = async (collection_name, sales) => {
    let collectionConfig = global.config[collection_name];

    let blacklistedSales = collectionConfig?.shop?.blacklistedSales;
    if (blacklistedSales) {
        try {
            blacklistedSales = blacklistedSales.split(",").map(sale => parseInt(sale));
        } catch (err) {}
    }
    let filtered = {};
    for (const sale of Object.keys(sales)) {
        if (!blacklistedSales?.includes(parseInt(sale))) {
            filtered[sale] = sales[sale];
        }
    }
    return filtered;
}

const getStoreItems = async (collection_name, store_type) => {
    console.log(collection_name, store_type);
    let sales = await CacheHelper.getSales(collection_name);
    console.debug(sales);

    sales = sales[(store_type === STORE_TYPE.CreditCard ? 'usd' : 'wax')];

    sales = await filterBlacklistedSales(collection_name, sales);

    if (sales && Object.keys(sales).length > 0) {
        let edited = {};
        for (let sale of Object.keys(sales)) {
            let { template_id } = sales[sale];
            let item = global.templates[collection_name][template_id];
            item.data = item.immutable_data;
            sales[sale].item = item;
            edited[sale] = sales[sale];
        }
        return edited;
    }
    return [];
}

const StoreItemsAPI = {
    STORE_TYPE,
    filterBlacklistedSales,
    getStoreItems,
};

export default StoreItemsAPI;
