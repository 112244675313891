import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Button, Header, Image } from "semantic-ui-react";
import StoreListItem from 'components/StoreListItem/StoreListItem';
import CartView from 'containers/CartView/CartView';
import PaymentSuccessWAX from 'containers/PaymentSuccessWAX/PaymentSuccessWAX';
import config from 'helper/config';
import CartAPI from 'helper/CartAPI';
import StoreItemsAPI from 'helper/StoreItemsAPI';
import WAXLogoWhite from 'assets/wax_white_SVG.svg';
import CacheHelper from "helper/CacheHelper";
import Loading from 'components/Loading/Loading';
import './StorePage.scss';
import ChainHelper from "helper/ChainHelper";
import {AppContext} from "provider/AppContext";
import {toast} from "react-toastify";

const STORE_TYPE = StoreItemsAPI.STORE_TYPE;

class StorePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            items: [],
            cart: [],
            selectedPaymentMethod: STORE_TYPE.WAX,
            wax_sales: {},
            wax_sales_count: 0,
            wax_sales_active: 0,
            wax_sales_first: null,
            usd_sales: {},
            usd_sales_count: 0,
            usd_sales_active: 0,
            usd_sales_first: null,
            redirect: null,
        };
    }

    componentDidMount() {
        let { collection_name } = this.props;
        this.cacheStore(collection_name).then(res => {
            this.loadStore(collection_name).then();
        });
    }

    countActiveSales = async (collection_name, sales, type = "") => {
        let active = 0;
        let first = null;

        for (const saleid of Object.keys(sales)) {
            let sale = sales[saleid];
            if (!sale.buy_end || sale.buy_end > new Date().getTime()) {
                if (first === null || sale.buy_start < first) {
                    first = sale.buy_start;
                }
            }
            if (
                sale.buy_start <= new Date().getTime()
                && (!sale.buy_end || sale.buy_end > new Date().getTime())
            ) {
                try {
                    if (type === "usd") {
                        let inventory = await fetch(global.pay_url + "/inventory?template_id=" + sale.template_id).then(res => res.json());
                        if (!inventory) {
                            throw new Error('No inventory');
                        }
                        if (parseInt(inventory.max_supply) <= 0 || parseInt(inventory.total) < parseInt(inventory.max_supply)) {
                            active++;
                        }
                    } else if (type === "wax") {
                        let template = global.templates[collection_name][sale.template_id];
                        // load reservations from smart contract
                        let totalSupply = parseInt(template?.issued_supply);
                        let premintCount = 0;
                        try {
                            let premints = await ChainHelper.get_table_rows("premint.nft", "stats", collection_name, 1, sale.template_id, sale.template_id);
                            if (premints.rows?.length > 0) {
                                let premint = premints.rows[0];
                                if (premint.active) {
                                    premintCount = premint.count;
                                }
                            }
                        } catch (err3) {
                            console.error("failed to fetch premint in contract", err3);
                        }
                        totalSupply -= premintCount;
                        let resCount = 0;
                        try {
                            let res1 = await ChainHelper.get_table_rows("sales.nft", "salerezqty", collection_name, 100, sale.template_id, sale.template_id);
                            if (res1 && res1.rows) {
                                if (res1.rows.length === 1) {
                                    resCount += res1.rows[0].total_reserved_quantity;
                                }
                            }
                        } catch (err4) {
                            console.error("failed to fetch supply reserved in contract", err4);
                        }
                        totalSupply += resCount;
                        if (parseInt(template?.max_supply) <= 0 || totalSupply > 0) {
                            active++;
                        }
                    }
                } catch (err1) { }
            }
        }

        return {
            total: Object.keys(sales).length || 0,
            active,
            first
        };
    }

    cacheStore = async (collection_name) => {
        let sales = await CacheHelper.getSales(collection_name);

        sales.wax = await StoreItemsAPI.filterBlacklistedSales(collection_name, sales.wax);
        sales.usd = await StoreItemsAPI.filterBlacklistedSales(collection_name, sales.usd);

        let wax_active = await this.countActiveSales(collection_name, sales.wax, "wax");
        console.log("WAX:", Object.keys(sales.wax).length, wax_active.active, wax_active.first);

        let usd_active = await this.countActiveSales(collection_name, sales.usd, "usd");
        console.log("USD:", Object.keys(sales.usd).length, usd_active.active, usd_active.first);

        this.setState({
            wax_sales: sales.wax,
            wax_sales_count: wax_active.total || 0,
            wax_sales_active: wax_active.active || 0,
            wax_sales_first: wax_active.first || null,
            usd_sales: sales.usd,
            usd_sales_count: usd_active.total || 0,
            usd_sales_active: usd_active.active || 0,
            usd_sales_first: usd_active.first || null,
        }, async () => {
            let method = STORE_TYPE.CreditCard;
            if (usd_active.active <= 0 && wax_active.active > 0) {
                method = STORE_TYPE.WAX;
            }
            if (usd_active.total <= 0 && wax_active.total > 0) {
                method = STORE_TYPE.WAX;
            }
            // if (usd_count.active < wax_count.active) {
            //     method = STORE_TYPE.WAX;
            // }
            // if (wax_sales.length > 0 && wax_count.first < usd_count.first) {
            //     method = STORE_TYPE.WAX;
            // }
            await this.changePaymentMethod(collection_name, method);
        });
    }

    loadStore = async (collection_name, force = false) => {
        let items = await StoreItemsAPI.getStoreItems(collection_name, this.state.selectedPaymentMethod);
        let cart = CartAPI.getCart(collection_name, this.state.selectedPaymentMethod);
        let newCart = cart;
        for (let cartItem of cart) {
            let { template_id } = cartItem;
            if (!items[template_id]) {
                // not for sale anymore (removed)
                newCart = CartAPI.setItemToCart(collection_name, template_id, 0, this.state.selectedPaymentMethod);
            }
            if (items[template_id] && items[template_id].buy_end <= new Date().getTime()) {
                // not for sale anymore (time)
                newCart = CartAPI.setItemToCart(collection_name, template_id, 0, this.state.selectedPaymentMethod);
            }
        }
        this.setState({
            loaded: true,
            items,
            cart: newCart,
        });
    }

    checkIfWhitelisted = async (template_id) => {
        try {
            const { wax } = this.context;
            const { rows: scopeRows } = await ChainHelper.get_table_by_scope("sales.nft", "whitelist", template_id, template_id);

            // if no whitelist table exists for `scope` [template_id], let user buy
            if(scopeRows.length === 0) {
                return true;
            }

            // if whitelist does exist, then check if user is whitelisted and let him buy if so
            else {
                const { rows: whitelistRows } = await ChainHelper.get_table_rows("sales.nft", "whitelist", template_id, 1, wax.userAccount, wax.userAccount);
                return whitelistRows.length !== 0;
            }
        } catch(err) {
            toast.error(err.toString());
        }
    }

    setItemToCart = (collection_name, template_id, quantity) => {
        this.setState({
            cart: CartAPI.setItemToCart(collection_name, template_id, quantity, this.state.selectedPaymentMethod)
        });
    }


    buyWithWAX = async () => {
        let { collection_name } = this.props;
        let { wax } = this.context;

        let total = 0;
        let { cart, items } = this.state;

        for (let cartItem of cart) {
            if (cartItem) {
                let item = items[cartItem.template_id];
                if (item) {
                    let [amount, tkn] = item.price.split(" ");
                    if (tkn === "USD") total += parseFloat(amount) * 100 * cartItem.quantity;
                    if (tkn === "WAX") total += parseFloat(amount) * cartItem.quantity;
                }
            }
        }

        try {
            let actions = [];
            if (total > 0) {
                actions.push({
                    account: "eosio.token",
                    name: "transfer",
                    authorization: [
                        {
                            actor: wax.userAccount,
                            permission: "active",
                        },
                    ],
                    data: {
                        from: wax.userAccount,
                        to: "sales.nft",
                        quantity: total.toFixed(8) + " WAX",
                        memo: "",
                    },
                });
            }

            for (let cartItem of cart) {
                if (cartItem) {
                    actions.push({
                        account: config.sales_contract,
                        name: "buyitem",
                        authorization: [
                            {
                                actor: wax.userAccount,
                                permission: "active",
                            },
                        ],
                        data: {
                            from: wax.userAccount,
                            collection_name,
                            template_id: cartItem.template_id,
                            quantity: cartItem.quantity,
                        },
                    });
                }
            }

            let transaction = await wax.api.transact({actions}, { expireSeconds: 120, blocksBehind: 3 });
            console.log(transaction);
            CartAPI.clearCart(collection_name, this.state.selectedPaymentMethod);
            this.setState({
                redirect: `/${collection_name}/tx?transaction=${transaction.transaction_id}`,
            });
        } catch (err) {
            console.error('ERROR:', err);
            toast.error(err.toString());
        }
    }

    changePaymentMethod = async (collection_name, payment_method) => {
        await this.setState({
            selectedPaymentMethod: payment_method,
            items: [],
        }, () => {
            this.loadStore(collection_name, true);
        });
    }

    render() {
        let { props: { collection_name, match: { params: { template_id } } } } = this;

        let { wax } = this.context;

        if (template_id === "cart") return <CartView {...this.props} />;
        if (template_id === "tx") return <PaymentSuccessWAX {...this.props} />;
        if (this.state.redirect) return <Redirect to={this.state.redirect} />;

        let total = 0;
        let quantity = 0;
        let { cart, items } = this.state;

        let available = true;
        let reason = null;

        if (Object.keys(items).length > 0) {
            for (let cartItem of cart) {
                if (cartItem) {
                    let item = items[cartItem.template_id];
                    if (item) {
                        if ((!["5ql.s.wam", "4je.m.wam"].includes(wax.userAccount)) && item.buy_start > new Date().getTime()) {
                            available = false;
                            reason = "NOT YET AVAILABLE";
                        }
                        if (item.buy_end > 0 && item.buy_end < new Date().getTime()) {
                            available = false;
                            reason = "NOT AVAILABLE ANYMORE";
                        }
                        let [amount, token] = item.price.split(" ");
                        quantity += cartItem.quantity;
                        if (token === "USD") total += parseFloat(amount) * 100 * cartItem.quantity;
                        if (token === "WAX") total += parseFloat(amount) * cartItem.quantity;
                    }
                }
            }
        }

        let k = 1;

        return (
            <main id="StorePage">
                <Header as='h1'>
                    {/* <Image circular src={''} /> */}
                    {collection_name.toUpperCase()}
                </Header>
                {wax.userAccount ?
                    <>
                        <div className="shop-items">
                            {this.state.wax_sales_count > 0 && this.state.usd_sales_count > 0 &&
                                <>
                                    Choose Sale Type:
                                    <div className="payment-options">
                                        {
                                            Object.keys(STORE_TYPE).map(key => {
                                                if (STORE_TYPE[key] === STORE_TYPE.WAX) {
                                                    if (this.state.wax_sales_count <= 0) return null;
                                                }
                                                if (STORE_TYPE[key] === STORE_TYPE.CreditCard) {
                                                    if (this.state.usd_sales_count <= 0) return null;
                                                }
                                                let classes = [];
                                                if (STORE_TYPE[key] === STORE_TYPE.WAX) {
                                                    classes.push("wax");
                                                }
                                                if (this.state.selectedPaymentMethod === STORE_TYPE[key]) {
                                                    classes.push("active");
                                                }
                                                return (
                                                    <div key={key} className="payment-option">
                                                        <Button
                                                            primary
                                                            size="medium"
                                                            className={classes.join(" ")}
                                                            onClick={() => this.changePaymentMethod(collection_name, STORE_TYPE[key])}
                                                        >
                                                            {STORE_TYPE[key] === STORE_TYPE.WAX ?
                                                                <Image src={WAXLogoWhite} alt="" />
                                                            :
                                                                STORE_TYPE[key]
                                                            }
                                                        </Button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }
                            {Object.keys(this.state.items).length <= 0 &&
                                <Loading />
                            }
                            {Object.keys(this.state.items).map(item => {
                                let filteredItemExists = cart.filter(cartItem => cartItem.template_id === parseInt(item));
                                let quantity = 0;
                                if (filteredItemExists.length > 0) {
                                    quantity = filteredItemExists[0].quantity;
                                }
                                return (
                                    <StoreListItem
                                        key={k++}
                                        {...this.state.items[item]}
                                        store_type={this.state.selectedPaymentMethod}
                                        checkIfWhitelisted={this.checkIfWhitelisted}
                                        setItemToCart={this.setItemToCart}
                                        quantity={quantity}
                                        userAccount={wax.userAccount}
                                    />);
                            })}
                        </div>
                        <div className="confirm">
                            {quantity > 0 ?
                                (available ?
                                    (this.state.selectedPaymentMethod === STORE_TYPE.CreditCard ?
                                        <Button fluid primary as={Link} to={`/${collection_name}/cart`}>CHECKOUT - ${(total / 100).toFixed(2)}</Button>
                                    :
                                        <Button fluid primary onClick={this.buyWithWAX}>{total > 0 ? <>BUY NOW - {total} WAX</> : "CONTINUE FOR FREE"}</Button>
                                    )
                                :
                                    <Button fluid disabled>{reason ? reason : "NOT AVAILABLE"}</Button>
                                )
                            :
                                <Button fluid disabled>ADD ITEMS TO CART</Button>
                            }
                        </div>
                    </>
                :
                    <div className="logged-out">
                        <div className="section-heading">
                            Please login to continue with checkout.
                        </div>
                        <Button as={'a'} primary compact href={global.pay_url + '/login'} referrerpolicy={'unsafe-url'}>Login</Button>
                    </div>
                }
            </main>
        )
    }
}

StorePage.contextType = AppContext;

export default StorePage;
