import React, {useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import {Button, Form, Grid, Message, Radio} from "semantic-ui-react";

const ShippingForm = (props) => {
    useEffect(() => {
        if (props.shippingOptions.length === 1) {
            props.handleShippingChange(props.shippingOptions[0].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let nextDisabled = true;
    if (
        props.shippingMethod
    ) {
        nextDisabled = false;
    }

    return (
        <Form>
            <Grid reversed='mobile vertically'>
                <Grid.Column className="virl-redemption" mobile={16} tablet={16} computer={8}>
                    <div className="section-heading">
                        Shipping Address
                    </div>
                    <div className="shipping-address">
                        {props.address.first_name} {props.address.last_name}<br />
                        {props.address.address_1}<br />
                        {(props.address.address_2 ? <>{props.address.address_2}<br /></> : "")}
                        {props.address.city}<br />
                        {props.address.state}, {props.address.postal_code}<br />
                        {props.address.country}<br />
                        <div className="shipping-change">
                            <span className="link" onClick={props.backToAddress}>Change</span>
                        </div>
                    </div>
                </Grid.Column>
                <Grid.Column className="virl-redemption" mobile={16} tablet={16} computer={8}>
                    <div className="section-heading">
                        Shipping Method
                    </div>
                    <div className="shipping-methods">
                        {Object.entries(props.shippingOptions).map(([key,method],i) => {
                            let cost = (method.cost / 100).toFixed(2) + " USD";
                            if (method.cost <= 0) {
                                cost = "FREE";
                            }
                            return (
                                <Form.Field key={key} onClick={() => props.handleShippingChange(method.id)}>
                                    <Radio
                                        label={method.name + ' (' + method.speed + ')'}
                                        name='shippingGroup'
                                        value={method.id}
                                        checked={props.shippingMethod === method.id}
                                    />
                                    <div className="shipping-cost">
                                        {cost}
                                    </div>
                                </Form.Field>
                            );
                        })}
                    </div>
                </Grid.Column>
            </Grid>
            {props.error &&
                <Message negative>
                    {props.error}
                </Message>
            }
            <Grid className="virl-controls" divided="vertically">
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Button fluid primary size="small" onClick={props.submitCheckout} loading={props.loadingCheckout} disabled={props.loadingCheckout || nextDisabled}>Next</Button>
                    </Grid.Column>
                    <Grid.Column>
                        <Button as={'a'} fluid basic size="small" onClick={props.backToAddress}>Go Back</Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    )
}

export default withRouter(ShippingForm);
