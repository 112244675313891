import sha256 from 'sha256';

let stringToNumber = string => {
    let hash = sha256(string);
    return parseInt(hash.substr(0, 12), 16);
}

global.stringToNumber = stringToNumber;

let rpcEndpoints = [
    {
        protocol: "https",
        host: "chain.wax.io",
        port: "443",
    }
    // {
    //     protocol: "https",
    //     host: "wax.greymass.com",
    //     port: "443",
    // }
];

if (process.env.REACT_APP_WAX_CHAIN_URL) {
    rpcEndpoints = [];
    let chain_url = new URL(process.env.REACT_APP_WAX_CHAIN_URL);
    let protocol = chain_url.protocol.replace(/:$/, "");
    rpcEndpoints = [
        {
            protocol: protocol,
            host: chain_url.hostname,
            port: chain_url.port || (chain_url.protocol === "http" ? 80 : 443),
        }
    ];
}

let RANDOM_NODE = rpcEndpoints[parseInt(Math.random() * rpcEndpoints.length)];
let NODE_API = RANDOM_NODE.protocol + "://" + RANDOM_NODE.host + (RANDOM_NODE.port !== "443" ? ":" + RANDOM_NODE.port : "");
let HISTORY_API = "https://wax.greymass.com";
//let HISTORY_API = "https://api.waxsweden.org";
//let HISTORY_API = "https://history.wax.io";

let get_table_rows = async (code, table, scope, limit=50, lower_bound=null, upper_bound=null, index_position = 1, key_type = null) => {
    let { rows, more } = await fetch(NODE_API + "/v1/chain/get_table_rows", {
        "body": JSON.stringify({
            json: true,
            code,
            scope,
            table,
            key_type,
            index_position,
            lower_bound,
            upper_bound,
            limit
        }),
        "method": "POST",
    }).then(res => res.json());
    return { rows, more };
}

let get_table_by_scope = async (contract, table, lower_bound, upper_bound) => {
    const { rows, more } = await fetch(`${NODE_API}/v1/chain/get_table_by_scope`, {
        method: 'POST',
        body: JSON.stringify({ 
            code: contract, 
            table: table,
            lower_bound,
            upper_bound,
            limit: 1, 
        })
    }).then(res => res.json());
    return { rows, more };
}

let get_transaction = async (transaction_id) => {
    return await fetch(HISTORY_API + "/v1/history/get_transaction?id=" + transaction_id + "&traces=false");
}

function destructureJSON (object=null, path="") {
    if (typeof object == "string" || typeof object == "number" || typeof object == "boolean") {
        return [{ keyindex: stringToNumber(path), key: path, value: object }]
    } else if (typeof object == "object") {
        let arr = [];
        if (object) {
            for (let key of Object.keys(object)) {
                arr.push(...destructureJSON(object[key], path + (path !== "" ? "." : "") + key))
            }
        }
        return arr;
    } else {
        throw new Error("Invalid object type: " + typeof object);
    }
}

function structJSON (rows) {
    let json = {};
    for (let row of rows) {
        let { key, value } = row;
        let sectionKeys = key.split(".");
        handleStructSection(json, sectionKeys, value);
    }
    return json;
}

function handleStructSection (main, sectionKeys=[], value=null) {
    let tempPath = main;
    for (let i = 0; i<sectionKeys.length; i++) {
        let key = sectionKeys[i];
        if (!tempPath[key]) tempPath[key] = {};
        if (sectionKeys.length === i + 1) tempPath[key] = value;
        else tempPath = tempPath[key];
    }
    return main;
}

const ChainHelper = {
    stringToNumber,
    rpcEndpoints,
    destructureJSON,
    structJSON,
    get_table_rows,
    get_transaction,
    get_table_by_scope
}

export default ChainHelper;
