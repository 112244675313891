import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Collection from "containers/Collection/Collection";
import '@stripe/stripe-js';
import Navbar from "components/Navbar/Navbar";
import Footer from "components/Footer/Footer";
import {Container, Image} from "semantic-ui-react";
import WdnyLogo from 'assets/wdny.svg';
import 'react-toastify/dist/ReactToastify.min.css';

const App = (props) => {
    return (
        <div className="app">
            <BrowserRouter>
                <Route
                    path="/:collection_name"
                    render={(props) =>
                        <Navbar {...props} />
                    }
                />
                <Container className="content">
                    <Switch>
                        <Route
                            exact
                            path="/"
                            render={() => {
                                let protocol = "https://";
                                let url = "wdny.io";
                                let href = protocol + url;
                                window.location = href;
                                return (
                                    <Container textAlign='center'>
                                        <br />
                                        <br />
                                        <Image size={'medium'} centered src={WdnyLogo} alt={"Wdny.io"} /><br />
                                        Redirecting to <a href={href} target="_blank" rel="noopener noreferrer" title={url}>{url}</a>...
                                    </Container>
                                );
                            }}
                        />
                        <Route
                            path="/:collection_name"
                            render={(props) =>
                                <Collection
                                    {...{...props, ...{
                                        collection_name: props.match.params.collection_name
                                    }}}
                                />
                            }
                        />
                    </Switch>
                </Container>
                <Route
                    path="/:collection_name"
                    render={(props) =>
                        <Footer {...props} />
                    }
                />
            </BrowserRouter>
            <ToastContainer />
        </div>
    )
}

export default App;
