const loadTos = async (collection_name) => {
    let tos = {};
    try {
        tos = await fetch(global.data_url + "/" + collection_name + "/tos.json?version=" + global.sitetime).then(res => res.json());
        if (!tos) {
            throw new Error("Error loading TOS");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.tos[collection_name] = tos;
    return tos;
}

const loadTemplates = async (collection_name) => {
    let templates = {};
    try {
        templates = await fetch(global.data_url + "/" + collection_name + "/templates.json?version=" + global.sitetime).then(res => res.json());
        if (!templates) {
            throw new Error("Error loading templates");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.templates[collection_name] = templates;
    return templates;
}

const getTemplates = (collection_name) => {
    return global.templates[collection_name];
}

const getTemplate = (collection_name, template_id) => {
    return global.templates[collection_name][template_id];
}

const loadSales = async (collection_name) => {
    let sales = {};
    try {
        sales = await fetch(global.data_url + "/" + collection_name + "/sales.json?version=" + global.sitetime).then(res => res.json());
        if (!sales) {
            throw new Error("Error loading sales");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.sales[collection_name] = sales;
    return sales;
}

const getSales = (collection_name) => {
    return global.sales[collection_name];
}

const getSale = (collection_name, sale_id) => {
    return global.sales[collection_name][sale_id];
}

const loadVirls = async (collection_name) => {
    let virls = {};
    try {
        virls = await fetch(global.data_url + "/" + collection_name + "/virls.json?version=" + global.sitetime).then(res => res.json());
        if (!virls) {
            throw new Error("Error loading virls");
        }
    } catch (err) {
        console.error(err.toString());
    }

    global.virls[collection_name] = virls;
    return virls;
}

const getVirls = (collection_name) => {
    return global.virls[collection_name];
}

const getVirl = (collection_name, template_id) => {
    return global.virls[collection_name][template_id];
}

const CacheHelper = {
    loadTos,
    loadTemplates,
    getTemplates,
    getTemplate,
    loadSales,
    getSales,
    getSale,
    loadVirls,
    getVirls,
    getVirl,
}

export default CacheHelper;
