import React, {useContext, useState} from 'react';
import {Button, Form, Grid, Message} from "semantic-ui-react";
import {Redirect} from "react-router-dom";
import {AppContext} from "provider/AppContext";
import {toast} from "react-toastify";

const CollectForm = (props) => {
    const {wax} = useContext(AppContext);

    const [sending, setSending] = useState(false);
    const [collected, setCollected] = useState(false);

    const submitVirl = async () => {
        setSending(true);
        if (!props.metadata?.items) throw new Error("System Error: item data missing");
        let items = JSON.parse(props.metadata?.items);

        if (!props.metadata?.nonce) throw new Error("System Error: nonce data missing");
        let nonce = props.metadata?.nonce;

        if (!props.metadata?.address_hash) throw new Error("System Error: address_hash data missing");
        let address_hash = props.metadata?.address_hash;

        try {
            let actions = [];
            actions.push({
                account: "atomicassets",
                name: "transfer",
                authorization: [
                    {
                        actor: wax.userAccount,
                        permission: 'active',
                    },
                ],
                data: {
                    from: wax.userAccount,
                    to: "redeem.nft",
                    asset_ids: items.map(item => item.asset_id),
                    memo: `${address_hash}:${nonce}`,
                },
            });

            console.log(actions);

            let transaction = await wax.api.transact({actions}, { expireSeconds: 120, blocksBehind: 3 });
            console.log(transaction);
            // set the items as collected if transaction successful
            setCollected(true);
        } catch (err) {
            console.error('ERROR:', err);
            toast.error(err.toString());
            props.setError(err.toString());
        }
        setSending(false);
    }

    let address = JSON.parse(props.metadata?.address);
    let shipping = JSON.parse(props.metadata?.shipping);

    let shippingCost = (shipping.cost / 100).toFixed(2) + " USD";
    if (shipping.cost <= 0) {
        shippingCost = "FREE";
    }

    if (collected) {
        return (
            <Redirect to={"/" + props.collection_name + "/redeem/" + props.asset_id + "/complete"} />
        )
    }

    return (
        <Form>
            <Grid reversed='mobile vertically'>
                <Grid.Column className="virl-redemption" mobile={16} tablet={16} computer={8}>
                    <div className="section-heading">
                        Shipping Address
                    </div>
                    <div className="shipping-address">
                        {address.first_name} {address.last_name}<br />
                        {address.address_1}<br />
                        {(address.address_2 ? <>{address.address_2}<br /></> : "")}
                        {address.city}<br />
                        {address.state}, {address.postal_code}<br />
                        {address.country}
                    </div>
                </Grid.Column>
                <Grid.Column className="virl-redemption" mobile={16} tablet={16} computer={8}>
                    <div className="section-heading">
                        Shipping Method
                    </div>
                    <div className="shipping-methods">
                        <Form.Field>
                            <span>{shipping.name + ' (' + shipping.speed + ')'}</span>
                            <div className="shipping-cost">
                                {shippingCost}
                            </div>
                        </Form.Field>
                    </div>
                </Grid.Column>
            </Grid>
            <div className="virl-deposit">
                Please click below to deposit your vIRL and complete the redemption process.
            </div>
            {props.error &&
                <Message negative>
                    {props.error}
                </Message>
            }
            {!collected && !props.error &&
                <Grid className="virl-controls" divided="vertically">
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Button fluid primary size="small" onClick={submitVirl} loading={sending} disabled={sending}>Deposit vIRL</Button>
                        </Grid.Column>
                        <Grid.Column>
                            {shipping.cost <= 0 &&
                                <Button as={'a'} fluid basic size="small" onClick={() => {
                                    props.history.push(`/${props.collection_name}/redeem/${props.asset_id}`);
                                }}>Cancel</Button>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }
        </Form>
    )
}

export default CollectForm;
