import React, {Component} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import '@stripe/stripe-js';
import StorePage from "containers/StorePage/StorePage";
import CartView from "containers/CartView/CartView";
import PaymentSuccess from "containers/PaymentSuccess/PaymentSuccess";
import PaymentSuccessWAX from "containers/PaymentSuccessWAX/PaymentSuccessWAX";
import RedeemVirl from "containers/RedeemVirl/RedeemVirl";
import CollectVirl from "containers/RedeemVirl/CollectVirl";
import CompleteVirl from "containers/RedeemVirl/CompleteVirl";
import ConfigLoader from "helper/ConfigLoader";
import Loading from "components/Loading/Loading";

class Collection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            checkoutEnabled: true
        };
    }

    componentDidMount() {
        let { collection_name } = this.props;
    
        ConfigLoader.loadConfig(collection_name).then(() => {
            this.setState({
                loading: false,
                checkoutEnabled: global.config[collection_name].checkout?.enable !== 'disabled'
            });
        });
    }

    render() {
        const { checkoutEnabled } = this.state;

        if (this.state.loading) {
            return (
                <Loading />
            );
        }

        return (
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path="/:collection_name/process"
                        render={(props) => <PaymentSuccess {...{...this.props, ...props}} />}
                    />
                    <Route
                        exact
                        path="/:collection_name/tx"
                        render={(props) => <PaymentSuccessWAX {...{...this.props, ...props}} />}
                    />
                    <Route
                        exact
                        path="/:collection_name/cart"
                        render={(props) => <CartView {...{...this.props, ...props}} />}
                    />
                    {
                        checkoutEnabled && (
                            <Route
                                exact
                                path="/:collection_name/products"
                                render={(props) => <StorePage {...{...this.props, ...props}} />}
                            />
                        )
                    }
                    <Route
                        exact
                        path="/:collection_name/redeem/:asset_id/collect"
                        render={(props) => <CollectVirl {...{...this.props, ...props, ...{
                            asset_id: props.match.params.asset_id
                        }}} />}
                    />
                    <Route
                        exact
                        path="/:collection_name/redeem/:asset_id/complete"
                        render={(props) => <CompleteVirl {...{...this.props, ...props, ...{
                            asset_id: props.match.params.asset_id
                        }}} />}
                    />
                    <Route
                        exact
                        path="/:collection_name/redeem/:asset_id"
                        render={(props) => <RedeemVirl {...{...this.props, ...props, ...{
                            asset_id: props.match.params.asset_id
                        }}} />}
                    />
                    <Route
                        exact
                        path="/:collection_name"
                        render={() => {
                            if(checkoutEnabled) {
                                window.location.href = "/" + this.props.collection_name + `/products`
                                return null;
                            }
                        }}
                    />
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Collection;