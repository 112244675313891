import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CheckoutAPI from 'helper/CheckoutAPI';
import PaymentIntentAPI from 'helper/PaymentIntentAPI';
import { loadStripe } from '@stripe/stripe-js';
import CartAPI from 'helper/CartAPI';
import StoreItemsAPI from 'helper/StoreItemsAPI';
import {Form, Button, Modal, Checkbox, Icon} from 'semantic-ui-react';
import {AppContext} from "provider/AppContext";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

import styles from './CartView.module.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class CartView extends Component {

    state = {
        email: null,
        dob_month: null,
        dob_day: null,
        dob_year: null,
        dob_saved: false,
        accountName: null,
        tos: false,
        error: null,
        loadingCheckout: false,
    }

    componentDidMount() {
        let { wax } = this.context;
        let accountName = null;

        let state = {};
        if (wax) accountName = wax.userAccount;
        state.accountName = accountName;

        let dob = sessionStorage.getItem('dob');
        if (dob) {
            dob = JSON.parse(dob);
            state.dob_month = dob.month;
            state.dob_day = dob.day;
            state.dob_year = dob.year;
            state.dob_saved = true;
        }

        this.setState(state);
    }

    getAge(dateString) {
        let today = new Date();
        let birthDate = new Date(dateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    saveAge = (e) => {
        e.preventDefault();
        try {
            let { dob_month, dob_day, dob_year } = this.state;
            let dob = dob_year + "/" + dob_month + "/" + dob_day;
            let age = this.getAge(dob);
            if (isNaN(age) || age <= 0) {
                throw new Error("Invalid date of birth");
            }
            this.setState({
                dob_saved: true,
            }, async () => {
                sessionStorage.setItem('dob', JSON.stringify({
                    month: this.state.dob_month,
                    day: this.state.dob_day,
                    year: this.state.dob_year,
                }));
            });
        } catch (err) {
            this.setError(err.message);
        }
    }

    startCheckout = () => {
        this.setState({
            loadingCheckout: true,
        }, async () => {
            let { props: { match: { params: { collection_name } } } } = this;
            let config = global.config[collection_name];
            let { email, dob_month, dob_day, dob_year, accountName } = this.state;
            try {
                if (config?.shop?.cart?.ageCheck === "1") {
                    let dob = dob_year + "/" + dob_month + "/" + dob_day;
                    let age = this.getAge(dob);
                    if (isNaN(age)) {
                        throw new Error("Invalid date of birth");
                    }
                    if (age > 0) {
                        if (age < (config?.shop?.cart?.minAge || 13)) {
                            throw new Error("You are not old enough to checkout");
                        }
                    }
                }
                let stripe = await stripePromise;
                let response = await CheckoutAPI.createCheckout(collection_name, accountName, email);
                if (response.error) return this.setError(response.error);
                // handle checkout session
                let { id, intent } = response;
                PaymentIntentAPI.savePaymentIntent(intent.id, intent);
                // begin stripe checkout
                CartAPI.clearCart(collection_name, StoreItemsAPI.STORE_TYPE.CreditCard);
                let stripeResponse = await stripe.redirectToCheckout({
                    sessionId: id,
                });
                if (stripeResponse.error) {
                    this.setError(stripeResponse.error);
                }
            } catch (err) {
                this.setError(err.message);
            }
        });
    }

    setError = message => {
        this.setState({
            loadingCheckout: false,
            error: message,
        });
    }

    updateStateField = (field, value) => {
        this.setState({ [field]: value }, async () => {
            console.dir(this.state);
        });
    }

    handleTosClick = (e, { checked }) => {
        this.updateStateField("tos", checked === true);
    }

    render() {
        let { props: { match: { params: { collection_name } } } } = this;
        let config = global.config[collection_name];

        let invalid = true;
        if (
            this.state.email &&
            this.state.accountName &&
            this.state.tos === true
        ) {
            if (config?.shop?.cart?.ageCheck === "1") {
                if (
                    this.state.dob_month &&
                    this.state.dob_day &&
                    this.state.dob_year &&
                    this.state.dob_saved === true
                ) {
                    invalid = false;
                }
            } else {
                invalid = false;
            }
        }
        let cart = CartAPI.getCart(collection_name, StoreItemsAPI.STORE_TYPE.CreditCard);
        console.log(collection_name, cart);

        let restriction = null;
        // validate cart

        return (
            <main id="CartView">
                <h1>{collection_name.toUpperCase()}</h1>
                <div><Link to={"/" + collection_name}>&larr; Back To Products</Link></div>
                <>
                    <div className="checkout">
                        <Form>
                            <Form.Field>
                                <label>Your Account Name</label>
                                <input type="text" id="accountName" disabled value={this.state.accountName} />
                            </Form.Field>
                            <Form.Field>
                                <label>Email</label>
                                <input type="email" id="email" onChange={e => this.updateStateField("email", e.target.value)} placeholder="Email" required />
                            </Form.Field>
                            {config?.shop?.cart?.ageCheck === "1" &&
                                <Form.Field>
                                    <label htmlFor="dob">Age Verification - Date of Birth</label>
                                    <div id="dob">
                                        <select id="dob_month" onChange={e => this.updateStateField("dob_month", e.target.value)} defaultValue={this.state.dob_month || ""} disabled={this.state.dob_saved} required>
                                            <option value="" disabled hidden>Month</option>
                                            <option value="1">January</option>
                                            <option value="2">February</option>
                                            <option value="3">March</option>
                                            <option value="4">April</option>
                                            <option value="5">May</option>
                                            <option value="6">June</option>
                                            <option value="7">July</option>
                                            <option value="8">August</option>
                                            <option value="9">September</option>
                                            <option value="10">October</option>
                                            <option value="11">November</option>
                                            <option value="12">December</option>
                                        </select>
                                        <select id="dob_day" onChange={e => this.updateStateField("dob_day", e.target.value)} defaultValue={this.state.dob_day || ""} disabled={this.state.dob_saved} required>
                                            <option value="" disabled hidden>Day</option>
                                            {[...Array(31)].map((e, i) => <option key={"day_"+(i+1)} value={i+1}>{i+1}</option>)}
                                        </select>
                                        <input type="text" id="dob_year" onChange={e => this.updateStateField("dob_year", e.target.value)} placeholder="Year" maxLength={4} pattern='^[0-9]{2,4}$' disabled={this.state.dob_saved} defaultValue={this.state.dob_year} style={{maxWidth: "120px"}} required />
                                        <Button primary onClick={this.saveAge} disabled={(this.state.dob_saved || (!this.state.dob_month || !this.state.dob_day || !this.state.dob_year))} formNoValidate={true}>{(this.state.dob_saved ? "Saved" : "Save")}</Button>
                                    </div>
                                    {/*{<small>You must be at least {config?.shop?.cart?.minAge || 13} to checkout.</small>}*/}
                                </Form.Field>
                            }
                            <Form.Field>
                                <div id="tos-group">
                                    <Checkbox label="By clicking here, you acknowledge that you have read and accept the following:" onChange={this.handleTosClick} required />
                                    <ul className="terms-list">
                                        <li>
                                            <a href="https://wdny.io/terms-of-service/" target="_blank" rel="noopener noreferrer">
                                                Terms of Service <Icon name='external' disabled size='small' />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://wdny.io/privacy/" target="_blank" rel="noopener noreferrer">
                                                Privacy Policy <Icon name='external' disabled size='small' />
                                            </a>
                                        </li>
                                        {global.tos[collection_name] && global.tos[collection_name].length > 0 &&
                                            <>
                                                {Object.keys(global.tos[collection_name]).map(key => {
                                                    let tos = global.tos[collection_name][key];
                                                    return (
                                                        <li key={tos.id}>
                                                            <Modal
                                                                trigger={<span className="link">{tos.title}</span>}
                                                                header={tos.title}
                                                                content={
                                                                    <ReactMarkdown
                                                                        className={styles.markdown}
                                                                        linkTarget={"_blank"}
                                                                        plugins={[gfm]}
                                                                        children={tos.content.split("\\n").join("\n")}
                                                                    />
                                                                }
                                                                actions={[{ key: 'close', content: 'Close', secondary: true }]}
                                                            />
                                                        </li>
                                                    )
                                                })}
                                            </>
                                        }
                                    </ul>
                                </div>
                            </Form.Field>
                        </Form>
                        {
                            this.state.error &&
                            <>
                                <hr />
                                <small style={{ color: "red" }}>
                                    {this.state.error}
                                </small>
                            </>
                        }
                    </div>
                    <div className="confirm">
                        {!this.state.loadingCheckout ?
                            <>
                                {!restriction ?
                                    <>
                                        {invalid ?
                                            <Button fluid disabled onClick={this.startCheckout}>Continue with checkout</Button>
                                        :
                                            <Button fluid primary onClick={this.startCheckout}>Continue with checkout</Button>
                                        }
                                    </>
                                :
                                    <div className={"warning-restriction"}>{ restriction }</div>
                                }
                            </>
                        :
                            <>
                                <hr />
                                Loading checkout...
                            </>
                        }
                    </div>
                </>
            </main>
        )
    }
}

CartView.contextType = AppContext;

export default CartView;
