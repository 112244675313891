import React, { Component } from 'react';
import {Button, Grid, Message} from "semantic-ui-react";
import ConfigLoader from "helper/ConfigLoader";
import Loading from "components/Loading/Loading";
import VirlAPI from "helper/VirlAPI";
import AddressForm from "./AddressForm";
import ShippingForm from "./ShippingForm";
import PaymentIntentAPI from "helper/PaymentIntentAPI";
import CartAPI from "helper/CartAPI";
import StoreItemsAPI from "helper/StoreItemsAPI";
import { loadStripe } from '@stripe/stripe-js';
import {Redirect} from "react-router-dom";
import PreviewVirl from "./PreviewVirl";
import './RedeemVirl.scss';
import API from "helper/API";
import {AppContext} from "provider/AppContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

class RedeemVirl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingShipping: false,
            loadingCheckout: false,
            countries: {},
            error: null,
            redirect: null,
            asset: null,
            country_code: "",
            country: "",
            first_name: "",
            last_name: "",
            address_1: "",
            address_2: "",
            city: "",
            state: "",
            postal_code: "",
            phone: "",
            email: "",
            shippingOptions: {},
            shippingMethod: null,
        };
    }

    componentDidMount() {
        let { collection_name, asset_id } = this.props;

        ConfigLoader.loadVirl(collection_name, asset_id).then(async data => {
            let countries = await VirlAPI.getCountries(asset_id);
            let status = await VirlAPI.getStatus([asset_id]);
            if (Object.keys(status).length > 0 && status[asset_id]) {
                if (status[asset_id]?.error) {
                    this.setState({
                        loading: false,
                        error: status[asset_id].error,
                    });
                    return;
                }
                if (parseInt(status[asset_id]?.status) === VirlAPI.RedemptionState.PAID) {
                    await this.setState({
                        redirect: <Redirect to={"/" + collection_name + "/redeem/" + asset_id + "/collect"}/>,
                    });
                    return;
                } else if (parseInt(status[asset_id]?.status) === VirlAPI.RedemptionState.COLLECTED) {
                    await this.setState({
                        redirect: <Redirect to={"/" + collection_name + "/redeem/" + asset_id + "/complete"}/>,
                    });
                    return;
                }
            }
            this.setState({
                loading: false,
                countries: countries,
                asset: data.asset,
                error: data.error || null
            });
        }).catch(err => {
            this.setState({
                loading: false,
                error: err.toString()
            });
        }).finally(() => {
            //this.tick();
        });
    }

    tick = () => {
        this.setState({}, () => {
            setTimeout(this.tick, 100);
        })
    }

    updateStateField = (field, value) => {
        this.setState({ [field]: value });
    }

    handleCountryChange = async (event, {value}) => {
        let country = this.state.countries.find(country => country.code === value);
        await this.updateStateField("country_code", country.code);
        await this.updateStateField("country", country.name);
    }

    handlePhoneChange = (value, data, event, formattedValue) => {
        this.updateStateField("phone", value.slice(data.dialCode.length));
    }

    handleShippingChange = async (value) => {
        await this.updateStateField("shippingMethod", value);
    }

    setError = (message) => {
        this.setState({
            error: message,
        });
    }

    backToAddress = () => {
        this.setState({
            loadingShipping: false,
            loadingCheckout: false,
            shippingOptions: {},
            shippingMethod: null,
            error: null,
        });
    }

    submitAddress = async () => {
        this.setState({
            loadingShipping: true,
            error: null,
        }, async () => {
            let { props: {collection_name} } = this;
            let { wax } = this.context;
            let accountName = wax.userAccount;
            let { asset, country_code, country, first_name, last_name, address_1, address_2, city, state, postal_code, phone, email } = this.state;
            let template_id = asset.template?.template_id || null;
            try {
                if (!country_code || !country || !first_name || !last_name || !address_1 || !city || !state || !postal_code || !phone || !email) {
                    throw new Error("Some fields are missing");
                }
                if (!collection_name) throw new Error("System Error: collection data missing");
                if (!asset) throw new Error("System Error: asset data missing");
                if (!accountName) throw new Error("System Error: user data missing");
                if (!template_id) throw new Error("System Error: template data missing");

                let address = {
                    country_code,
                    country,
                    first_name,
                    last_name,
                    address_1,
                    address_2,
                    city,
                    state,
                    postal_code,
                    phone,
                    email
                };

                let items = [
                    {
                        asset_id: asset.asset_id,
                        template_id: template_id
                    }
                ];

                let response = await VirlAPI.requestShipping(collection_name, accountName, address, items);
                if (response.error) throw new Error(response.error);

                this.setState({
                    loading: false,
                    loadingShipping: false,
                    error: null,
                    shippingOptions: response.shipping_options,
                });
            } catch (err) {
                this.setError(err.message);
                this.setState({
                    loading: false,
                    loadingShipping: false,
                });
            }
        });
    }

    submitCheckout = async () => {
        this.setState({
            loadingCheckout: true,
            error: null,
        }, async () => {
            let { props: {collection_name} } = this;
            let { wax } = this.context;
            let accountName = wax.userAccount;
            let { asset, country_code, country, first_name, last_name, address_1, address_2, city, state, postal_code, phone, email, shippingMethod } = this.state;
            let template_id = asset.template?.template_id || null;
            try {
                if (!country_code || !country || !first_name || !last_name || !address_1 || !city || !state || !postal_code || !phone || !email) {
                    throw new Error("Some fields are missing");
                }
                if (!shippingMethod) throw new Error("Invalid shipping method");
                if (!collection_name) throw new Error("System Error: collection data missing");
                if (!asset) throw new Error("System Error: asset data missing");
                if (!accountName) throw new Error("System Error: user data missing");
                if (!template_id) throw new Error("System Error: template data missing");

                let address = {
                    country_code,
                    country,
                    first_name,
                    last_name,
                    address_1,
                    address_2,
                    city,
                    state,
                    postal_code,
                    phone,
                    email
                };

                let items = [
                    {
                        asset_id: asset.asset_id,
                        template_id: template_id
                    }
                ];

                let stripe = await stripePromise;
                let response = await VirlAPI.submitRedemption(collection_name, accountName, address, items, shippingMethod);
                if (response.error) throw new Error(response.error);
                if (!response.type) throw new Error("Error: Invalid response from payment server, no type returned");
                if (!response.address_hash) throw new Error("Error: Invalid response from payment server, no address_hash returned");
                if (!response.nonce) throw new Error("Error: Invalid response from payment server, no nonce returned");
                let {nonce, address_hash} = response;

                if (response.type === "stripe") {
                    // handle checkout session
                    let {id, intent} = response;
                    PaymentIntentAPI.savePaymentIntent(intent.id, intent, "virl");
                    // begin stripe checkout
                    CartAPI.clearCart(collection_name, StoreItemsAPI.STORE_TYPE.CreditCard);
                    let stripeResponse = await stripe.redirectToCheckout({
                        sessionId: id,
                    });
                    if (stripeResponse.error) {
                        throw new Error(stripeResponse.error);
                    }
                } else {
                    let shipping = this.state.shippingOptions.find(method => method.id === shippingMethod);
                    let intent = {
                        type: "free",
                        metadata: {
                            accountName: accountName,
                            collection_name,
                            items: JSON.stringify(
                                items.map((item) => ({
                                    asset_id: item.asset_id,
                                    template_id: item.template_id,
                                }))
                            ),
                            nonce,
                            address_hash,
                            address: JSON.stringify({
                                country,
                                first_name,
                                last_name,
                                address_1,
                                address_2,
                                city,
                                state,
                                postal_code,
                                phone,
                                email,
                            }),
                            shipping: JSON.stringify({
                                name: shipping.name,
                                speed: shipping.speed,
                                cost: shipping.cost,
                            }),
                        }
                    };
                    await PaymentIntentAPI.savePaymentIntent(asset.asset_id, intent, "virl");
                    await this.setState({
                        redirect: <Redirect to={"/" + collection_name + "/redeem/" + asset.asset_id + "/collect"} />,
                    });
                }

                this.setState({
                    loading: false,
                    loadingShipping: false,
                    loadingCheckout: false,
                    error: null,
                });
            } catch (err) {
                this.setError(err.message);
                this.setState({
                    loading: false,
                    loadingShipping: false,
                    loadingCheckout: false,
                });
            }
        });
    }

    render() {
        let { wax } = this.context;

        if (this.state.redirect) {
            return this.state.redirect;
        }

        if (this.state.loading) {
            return (
                <Loading />
            );
        }

        if (!this.state.asset && this.state.error) {
            return (
                <Message negative>
                    <p>{this.state.error}</p>
                </Message>
            );
        }

        let address = {
            country_code: this.state.country_code,
            country: this.state.country,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            address_1: this.state.address_1,
            address_2: this.state.address_2,
            city: this.state.city,
            state: this.state.state,
            postal_code: this.state.postal_code,
            phone: this.state.phone,
            email: this.state.email,
        };

        let virl = global.virls[this.props.collection_name].virls[this.state.asset.template.template_id];

        let diff_start = parseInt(virl.start_ms) - new Date().getTime();
        let diff_end = null;
        if (parseInt(virl.end_ms) > 0) {
            diff_end = parseInt(virl.end_ms) - new Date().getTime();
        }

        return (
            <main id="RedeemVirl">
                <Grid reversed='mobile vertically'>
                    <Grid.Column className="virl-redemption" mobile={16} tablet={8} computer={8}>
                        {this.state.error ? (
                            <>
                                <Message negative>
                                    <p>{this.state.error}</p>
                                </Message>
                                <Button primary onClick={() => this.setState({ shippingOptions: {}, error: null })} formNoValidate={true}>
                                  Go Back
                                </Button>
                             </>
                        )
                        :
                            <>
                                {diff_start > 0 &&
                                    <Message>
                                        <p>vIRL can be redeemed in {API.msToTime(diff_start)}</p>
                                    </Message>
                                }
                                {diff_start <= 0 && (virl.end_ms <= 0 || (virl.end_ms > 0 && diff_end > 0)) &&
                                    <>
                                        <h2>Redeem Physical Goods</h2>
                                        {!wax.userAccount &&
                                            <div className="logged-out">
                                                <div className="section-heading">
                                                    Please login to continue with redemption.
                                                </div>
                                                <Button as={'a'} primary compact href={global.pay_url + '/login'} referrerpolicy={'unsafe-url'}>Login</Button>
                                            </div>
                                        }
                                        {wax.userAccount &&
                                            <>
                                                {this.state.asset.owner !== wax.userAccount ?
                                                        <Message>
                                                            <p>
                                                                This vIRL does not belong to <strong>{wax.userAccount}</strong>,
                                                                only the owner can redeem.
                                                            </p>
                                                    </Message>
                                                :
                                                    <>
                                                        {Object.keys(this.state.shippingOptions).length <= 0 &&
                                                            <AddressForm
                                                                {...this.props}
                                                                countries={this.state.countries}
                                                                error={this.state.error}
                                                                address={address}
                                                                loadingShipping={this.state.loadingShipping}
                                                                updateStateField={this.updateStateField}
                                                                handleCountryChange={this.handleCountryChange}
                                                                handlePhoneChange={this.handlePhoneChange}
                                                                submitAddress={this.submitAddress}
                                                            />
                                                        }
                                                        {Object.keys(this.state.shippingOptions).length > 0 &&
                                                            <ShippingForm
                                                                {...this.props}
                                                                error={this.state.error}
                                                                address={address}
                                                                shippingOptions={this.state.shippingOptions}
                                                                backToAddress={this.backToAddress}
                                                                shippingMethod={this.state.shippingMethod}
                                                                handleShippingChange={this.handleShippingChange}
                                                                loadingCheckout={this.state.loadingCheckout}
                                                                submitCheckout={this.submitCheckout}
                                                            />
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </Grid.Column>
                    <PreviewVirl asset={this.state.asset} />
                </Grid>
            </main>
        )
    }
}

RedeemVirl.contextType = AppContext;

export default RedeemVirl;
