import React from 'react';
import { withRouter } from 'react-router-dom';
import {Button, Form, Grid, Message} from 'semantic-ui-react';

const AddressForm = (props) => {
    let nextDisabled = true;
    if (
        props.address.country_code
        && props.address.first_name
        && props.address.last_name
        && props.address.address_1
        && props.address.city
        && props.address.state
        && props.address.postal_code
        && props.address.phone
        && props.address.email
    ) {
        nextDisabled = false;
    }

    return (
        <Form>
            <div className="section-heading">
                Enter shipping address
            </div>
            <Form.Dropdown
                name="country"
                placeholder='Country'
                required
                fluid
                search
                selection
                options={props.countries.map((country) => ({
                    key: country.code,
                    value: country.code,
                    flag: country.code,
                    text: country.name,
                }))}
                onChange={props.handleCountryChange}
                defaultValue={props.address.country_code}
            />
            <Form.Group widths='equal'>
                <Form.Input size="mini" name="first_name" required placeholder="First Name" width={8} mobile={8} onChange={e => props.updateStateField("first_name", e.target.value)} value={props.address.first_name} />
                <Form.Input size="mini" name="last_name" required placeholder="Last Name" width={8} mobile={8} onChange={e => props.updateStateField("last_name", e.target.value)} value={props.address.last_name} />
            </Form.Group>
            <Form.Input size="mini" name="address_1" required placeholder="Address Line 1" width={16} onChange={e => props.updateStateField("address_1", e.target.value)} value={props.address.address_1} />
            <Form.Input size="mini" name="address_2" placeholder="Address Line 2" width={16} onChange={e => props.updateStateField("address_2", e.target.value)} value={props.address.address_2} />
            <Form.Input size="mini" name="city" required placeholder="City" width={16} onChange={e => props.updateStateField("city", e.target.value)} value={props.address.city} />
            <Form.Input size="mini" name="state" required placeholder="State" width={16} onChange={e => props.updateStateField("state", e.target.value)} value={props.address.state} />
            <Form.Input size="mini" name="postal_code" required placeholder="Postal Code" width={16} onChange={e => props.updateStateField("postal_code", e.target.value)} value={props.address.postal_code} />
            <Form.Input size="mini" name="phone" required placeholder="Phone Number" width={16} onChange={e => props.updateStateField("phone", e.target.value)} value={props.address.phone} />
            <Form.Input size="mini" name="email" required placeholder="Email" width={16} onChange={e => props.updateStateField("email", e.target.value)} value={props.address.email} />
            {props.error &&
                <Message negative>
                    {props.error}
                </Message>
            }
            <Grid className="virl-controls" divided="vertically">
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Button fluid primary size="small" onClick={props.submitAddress} loading={props.loadingShipping} disabled={props.loadingShipping || nextDisabled}>Next</Button>
                    </Grid.Column>
                    {/*<Grid.Column>*/}
                    {/*    <Button as={'a'} fluid basic size="small" onClick={props.history.goBack}>Cancel</Button>*/}
                    {/*</Grid.Column>*/}
                </Grid.Row>
            </Grid>
        </Form>
    )
}

export default withRouter(AddressForm);
