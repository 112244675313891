import React from 'react';
import {Container, Image, Menu} from "semantic-ui-react";
import './Footer.scss';

import WdnyLogo from 'assets/wdny.svg';
import WaxLogo from 'assets/wax.svg';

const Footer = () => {
    return (
        <footer>
            <Container className="footbar">
                <div className="footbox">
                    <div className="logo">
                        <a href="https://wdny.io" target="_blank" rel="noopener noreferrer" title="Wdny.io">
                            <Image src={WdnyLogo} alt="Wdny.io" />
                        </a>
                    </div>
                    <div className="copyright">
                        &copy; {(new Date()).getFullYear()} Wdny.io All rights reserved.
                    </div>
                    <div className="links">
                        <Menu secondary>
                            <Menu.Item>
                                <a href="https://wax.io" target="_blank" rel="noopener noreferrer" title="WAX">
                                    <Image width="100" src={WaxLogo} alt="WAX" />
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="https://wdny.io/terms-of-service" target="_blank" rel="noopener noreferrer" title="Terms of Service">
                                    TERMS OF SERVICE
                                </a>
                            </Menu.Item>
                            <Menu.Item>
                                <a href="https://wdny.io/privacy" target="_blank" rel="noopener noreferrer" title="Privacy">
                                    PRIVACY
                                </a>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            </Container>
        </footer>
    );
}

export default Footer;
