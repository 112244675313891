import React from 'react';
import ReactDOM from 'react-dom';
import App from 'components/App/App';
import {AppProvider} from "./provider/AppProvider";
import 'semantic-ui-css/semantic.min.css';
import './index.scss';

global.data_url = process.env.REACT_APP_DATA_URL || "https://sale-data.wdny.io";
global.pay_url = process.env.REACT_APP_PAY_URL || "https://payments.wdny.io";
global.sitetime = Math.floor(Date.now() / 1000);

ReactDOM.render(
  <AppProvider>
    <App />
  </AppProvider>,
  document.getElementById('root')
);
