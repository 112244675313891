import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {Button, Image, Menu, Sticky} from "semantic-ui-react";
import {AppContext} from "provider/AppContext";
import './Navbar.scss';

import WdnyLogo from 'assets/wdny.svg';

const Navbar = () => {
    const {wax} = useContext(AppContext);

    const doLogout = async () => {
        await fetch(`${global.pay_url}/logout`, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include'
        }).then(res => {
            if (res.status === 200) {
                window.location.reload();
            }
        });
    };

    return (
        <Sticky as="header">
            <div className="navbar">
                <div className="navbox">
                    <div className="logo">
                        <Link to="/" title={"Wdny.io"}>
                            <Image src={WdnyLogo} alt={"Wdny.io"} />
                        </Link>
                    </div>
                    <div className="navright">
                        <Menu secondary>
                            {wax.userAccount &&
                                <Menu.Item fitted>
                                    Welcome,&nbsp;
                                    <span className="username">{wax.userAccount}</span>
                                </Menu.Item>
                            }
                            <Menu.Item fitted>
                                {wax.userAccount ?
                                    <Button primary compact onClick={doLogout}>Logout</Button>
                                :
                                    <Button as={'a'} primary compact href={global.pay_url + '/login'} referrerpolicy={'unsafe-url'}>Login</Button>
                                }
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            </div>
        </Sticky>
    )
}

export default Navbar;
