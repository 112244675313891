const requestShipping = async (collection_name, account_name, address, items) => {
    return await fetch(global.pay_url + "/virl/shipping", {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            collection_name: collection_name,
            account_name: account_name,
            country_code: address.country_code,
            country: address.country,
            first_name: address.first_name,
            last_name: address.last_name,
            address_1: address.address_1,
            address_2: address.address_2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            phone: address.phone,
            email: address.email,
            items: items
        })
    }).then(res => res.json());
}

const submitRedemption = async (collection_name, account_name, address, items, shipping) => {
    let asset_id = items[0].asset_id;
    let url = window.location.origin + "/" + collection_name + "/redeem/" + asset_id;

    return await fetch(global.pay_url + "/virl/redeem", {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        body: JSON.stringify({
            cancel_url: url,
            success_url: url + "/collect",
            collection_name: collection_name,
            account_name: account_name,
            country_code: address.country_code,
            country: address.country,
            first_name: address.first_name,
            last_name: address.last_name,
            address_1: address.address_1,
            address_2: address.address_2,
            city: address.city,
            state: address.state,
            postal_code: address.postal_code,
            phone: address.phone,
            email: address.email,
            items: items,
            shipping: shipping,
        })
    }).then(res => res.json());
}

const getStatus = async (asset_ids) => {
    let statuses = await fetch(
      global.pay_url + "/virl/status?assets=" + asset_ids.join(','),
      {
          credentials: 'include',
      }
    ).then(res => res.json());
    console.log("statuses", statuses);
    if (!statuses) {
        console.log(null);
        return null;
    }
    return statuses;
}

const getCountries = async (asset_id) => {
    let countries = await fetch(
      global.pay_url + "/virl/shipping?asset_id=" + asset_id,
      {
          credentials: 'include'
      }
    ).then(res => res.json());
    if (!countries) {
        throw new Error('No viable countries');
    }
    return countries;
}

const RedemptionState = {
    REQUESTED: -1,
    INITIATED: 0,
    PAID: 1,
    COLLECTED: 2,
    RECEIVERAND: 3,
    CLAIMED: 4,
}

const VirlAPI = {
    requestShipping,
    submitRedemption,
    getStatus,
    getCountries,
    RedemptionState
};

export default VirlAPI;
