import React from 'react';
import IPFSHelper from "helper/IPFSHelper";
import VideoAsset from "components/VideoAsset/VideoAsset";
import {Image} from "semantic-ui-react";

// expects nft attribute to be Atomic Assets object
const NFTPreview = (props) => {
    let nft = props.nft;

    if (!nft.data) {
        nft.data = nft.immutable_data;
    }

    let preview = (
        <Image className={props.className} src={IPFSHelper.formatToUrl(nft?.data?.img, props.size)} alt={String(nft?.data?.name)} onClick={props.onClick} style={props.style} />
    );

    if (nft?.data?.video && !nft?.data?.img) {
        preview = (
            <VideoAsset className={props.className} src={IPFSHelper.formatToUrl(nft?.data?.video, props.size)} alt={nft?.data?.name} onClick={props.onClick} style={props.style} />
        );
    }

    return preview;
}

export default NFTPreview;
