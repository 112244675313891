function msToTime(duration) {
    let milliseconds = parseInt((duration % 1000) / 10),
        seconds = Math.floor((duration / 1000) % 60),
        minutes = Math.floor((duration / (1000 * 60)) % 60),
        hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
        days = Math.floor((duration / (1000 * 60 * 60 * 24)) % 365);

    days = days < 10 ? "0" + days : days;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    milliseconds = milliseconds < 10 ? "0" + milliseconds : milliseconds;
    return (
        ((parseFloat(days) > 0) ? days + ":" : "") +
        hours +
        ":" +
        minutes +
        ":" +
        seconds +
        (parseFloat(hours) < 1 ?  "." + milliseconds : "")
    );
}

const API = {
    msToTime,
};

export default API;