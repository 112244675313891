
const savePaymentIntent = (pid, paymentIntent, type = null) => {
    localStorage.setItem(pid, JSON.stringify(paymentIntent));
    localStorage.setItem("recent_payment_intent" + (type ? "_" + type : ""), pid);
}

const getPaymentIntent = pid => {
    let paymentIntent = localStorage.getItem(pid);
    if (paymentIntent) {
        paymentIntent = JSON.parse(paymentIntent);
    } else {
        paymentIntent = null;
    }
    return paymentIntent;
}

const getRecentPaymentIntent = (type = null) => {
    let pid = localStorage.getItem("recent_payment_intent" + (type ? "_" + type : ""));
    return getPaymentIntent(pid);
}

const getUpdatedPaymentIntent = async (pid, client_secret) => {
    let newPaymentIntent = await fetch(global.pay_url + `/intent/get?id=${encodeURI(pid)}&client_secret=${encodeURI(client_secret)}`).then(res => res.json());
    console.log(newPaymentIntent);
    return newPaymentIntent;
}

const PaymentIntentAPI = {
    savePaymentIntent,
    getPaymentIntent,
    getRecentPaymentIntent,
    getUpdatedPaymentIntent
};

export default PaymentIntentAPI;