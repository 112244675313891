import React, { Component } from 'react';
import {Loader} from "semantic-ui-react";

class Loading extends Component {
    render() {
        return (
            <Loader active inline='centered' style={{marginTop: "1rem", marginBottom: "1rem"}} />
        );
    }
}

export default Loading;
