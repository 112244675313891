import CartAPI from 'helper/CartAPI';
import StoreItemsAPI from 'helper/StoreItemsAPI';

const createCheckout = async (collection_name, accountName, email) => {
    return await fetch(global.pay_url + "/pay", {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            cancelUrl: window.location.origin + "/" + collection_name + "/process", // TODO: Revert this once payments server is deployed
            successUrl: window.location.origin + "/" + collection_name + "/process",
            collection_name: collection_name,
            items: CartAPI.getCart(collection_name, StoreItemsAPI.STORE_TYPE.CreditCard),
            accountName: accountName,
            customerEmail: email,
        })
    }).then(res => res.json());
}

const getInventory = async (template_ids) => {
    let inventory = await fetch(global.pay_url + "/inventory?template_id=" + template_ids.join(',')).then(res => res.json());
    if (!inventory) {
        throw new Error('No inventory');
    }
    return inventory;
}

const CheckoutAPI = {
    createCheckout,
    getInventory
}

export default CheckoutAPI;
