import React, {Component} from 'react';
import PaymentIntentAPI from 'helper/PaymentIntentAPI'
import StoreItemsAPI from 'helper/StoreItemsAPI';
import Loading from "components/Loading/Loading";
import NFTPreview from "components/NFTPreview/NFTPreview";

const endedStatus = [4];

class PaymentSuccess extends Component {
    state = {
        loading: true,
        stripe_id: null,
        client_secret: null,
        intent: null,
        items: []
    };

    componentDidMount() {
        this.loadPaymentIntent().then(() => {
            this.updatePaymentIntent().then();
        });
    }

    loadPaymentIntent = async () => {
        let paymentIntent = await PaymentIntentAPI.getRecentPaymentIntent();
        console.log(paymentIntent);
        if (!paymentIntent) {
            this.setState({
                loading: false
            });
            return;
        }
        console.log(paymentIntent?.metadata);
        let {metadata: {collection, items}} = paymentIntent;
        try {
            items = JSON.parse(items);
        } catch (err) {}
        let shopItems = await StoreItemsAPI.getStoreItems(collection, StoreItemsAPI.STORE_TYPE.CreditCard);
        for (let item of items) {
            let template = item.template || item.template_id;
            item.shopItem = shopItems[template];
            item.template_id = parseInt(template);
            delete item.template;
        }
        this.setState({
            loading: false,
            intent: {stripe_id: paymentIntent.id},
            stripe_id: paymentIntent.id,
            client_secret: paymentIntent.client_secret,
            items
        });
    }

    updatePaymentIntent = async () => {
        if (this.state.stripe_id && this.state.client_secret) {
            let intent = await PaymentIntentAPI.getUpdatedPaymentIntent(this.state.stripe_id, this.state.client_secret);
            console.log(intent);
            let items = {};
            try {
                items = JSON.parse(intent?.items);
            } catch (err) {}
            let shopItems = await StoreItemsAPI.getStoreItems(intent?.collection, StoreItemsAPI.STORE_TYPE.CreditCard);
            for (let item of items) {
                let template = item.template || item.template_id;
                item.shopItem = shopItems[template];
                item.template_id = parseInt(template);
                delete item.template;
            }
            this.setState({
                loading: false,
                intent: intent,
                items: items,
            }, () => {
                if (endedStatus.includes(intent?.captured)) return;
                if (intent?.captured === 2 && intent?.transaction_id) return;
                setTimeout(this.updatePaymentIntent, 5000);
            });
        } else {
            setTimeout(this.updatePaymentIntent, 5000);
        }
    }

    purchaseStatus = () => {
        let intent = this.state.intent;

        let issuedTransaction = intent?.transaction_id || null;
        let collection_name = intent?.collection;
        let success = false;
        let title = "Order Processing";
        let memo = "We've received your order and are processing your payment. This may take a few moments...";
        switch (intent?.captured) {
            // case "requires_payment_method":
            //     title = "Order requires payment method";
            //     memo = "Waiting for payment to be provided.";
            //     break;
            case 4:
                title = "Order Canceled";
                memo = "We're sorry to inform you that your order got canceled, funds have not been withdrawn from you account. If you have pending charges your bank can confirm that the charge will be removed within the next 7-21 business days.";
                break;
            // case "requires_action":
            //     title = "Payment requires an action";
            //     memo = "Your payment requires an action. Check your banking app for an action.";
            //     break;
            // case "requires_confirmation":
            //     title = "Payment requires confirmation";
            //     memo = "Your payment requires confirmation. Check your banking app for a confirmation.";
            //     break;
            case 2:
                success = true;
                if (!issuedTransaction) {
                    title = "Payment Successful!";
                    memo = (
                        <>
                            We've received your payment and are sending your digital packs! It may take a few moments for your packs to appear in your <a href={"https://wallet.wax.io/nfts?collection_name=" + collection_name} target="_blank" rel="noopener noreferrer">wallet</a>.
                        </>
                    );
                } else {
                    title = "Congratulations!";
                    memo = (
                        <>
                            Hurray! You've received your packs. Go back and unpack them, view them in your <a href={"https://wallet.wax.io/nfts?collection_name=" + collection_name} target="_blank" rel="noopener noreferrer">wallet</a>, or trade them. Pretty cool, huh?<br/><small>Transaction: <a href={"https://wax.bloks.io/transaction/" + issuedTransaction} target="_blank" rel="noopener noreferrer">{issuedTransaction.substr(0, 6)}</a></small>
                        </>
                    );
                }
                break;
            default:

        }
        return (
            <section className="order-status">
                <h2 className={success ? "success" : ""}>{title}</h2>
                <p>{memo}</p>
            </section>
        );
    }

    render() {
        let intent = this.state.intent;
        let {items} = this.state;

        return (
            <main id="PaymentSuccess">
                <h1 className={"light"}>
                    Order Summary&nbsp;
                    <small style={{fontSize: "1rem", fontWeight: 500}}>
                        #{this.state?.stripe_id?.substr(3)}
                    </small>
                </h1>
                <br />
                {this.state.loading &&
                    <Loading />
                }
                {!this.state.loading && !intent &&
                    <>
                        Error: Payment not found.
                    </>
                }
                {!this.state.loading && intent &&
                    <>
                        {this.purchaseStatus()}
                        <h3>Your Order:</h3>
                        <div className={"shop-items noheight"}>
                            {
                                items?.map(item => {
                                    let {template_id, shopItem, quantity, unit_amount} = item;
                                    return (
                                        <CartItemView key={template_id} item={shopItem.item} quantity={quantity} price={"$" + parseFloat(unit_amount).toFixed(2)} />
                                    )
                                })
                            }
                        </div>
                    </>
                }
            </main>
        )
    }
}

class CartItemView extends Component {
    render() {
        let {item, price, quantity} = this.props;
        return (
            <div className="shop-item">
                <div className="product">
                    <div className="preview">
                        <NFTPreview nft={item} size={"100x150"} />
                    </div>
                </div>
                <div className="info">
                    <div className="name">
                        {item.name}
                    </div>
                    <div className="subname"><b>{quantity}x {price}</b>{item?.data?.contains && <> - <b>{item?.data?.contains}</b></>}</div>
                </div>
                {/* <div className="quantity">
                    <div className="total"></div>
                </div> */}
            </div>
        )
    }
}

export default PaymentSuccess;
