import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import API from 'helper/API';
import ChainHelper from "helper/ChainHelper";
import NFTPreview from "components/NFTPreview/NFTPreview";
import './StoreListItem.scss';
import { Button } from 'semantic-ui-react';

class StoreListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inventory: false,
            supply: parseInt(props.item?.issued_supply) - parseInt(props.reserved),
            supply_display: " ...",
            max_supply: parseInt(props.item?.max_supply) - (props.type === "wax" ? parseInt(props.reserved) : 0),
            claimed: 0,
            isUserWhitelisted: true
        }
    }

    componentDidMount() {
        const {
            template_id,
            checkIfWhitelisted,
        } = this.props;

        checkIfWhitelisted(template_id).then(result => {
            this.setState({
                isUserWhitelisted: result
            })
        })
        this.updateSupply().then();
        this.checkFreeClaim().then();
        this.tick();
    }

    tick = () => {
        this.setState({}, () => {
            setTimeout(this.tick, 50);
        })
    }

    charToSymbol = (c) => {
        if (typeof c == 'string') c = c.charCodeAt(0);
        if (c >= 'a'.charCodeAt(0) && c <= 'z'.charCodeAt(0)) {
            return c - 'a'.charCodeAt(0) + 6;
        }
        if (c >= '1'.charCodeAt(0) && c <= '5'.charCodeAt(0)) {
            return c - '1'.charCodeAt(0) + 1;
        }
        return 0;
    }

    nameToUint64 = (s) => {
        let n = 0n;
        let i = 0;
        for (; i < 12 && s[i]; i++) {
            n |= BigInt(this.charToSymbol(s.charCodeAt(i)) & 0x1f) << BigInt(64 - 5 * (i + 1));
        }
        if (i === 12) {
            n |= BigInt(this.charToSymbol(s.charCodeAt(i)) & 0x0f);
        }
        return n.toString();
    }

    updateQuantity = value => {
        let { props: { template_id, buy_end, buy_max, match: { params: { collection_name } }, setItemToCart } } = this;

        let quantity = parseInt(value);
        if (buy_end > 0 && buy_end <= new Date().getTime()) quantity = 0;

        // quantity validation
        if (`${quantity}` === "NaN") quantity = 0;
        if (quantity > buy_max && buy_max > 0) quantity = buy_max;
        // What if they dont want to buy it? Validation only
        // if (quantity < buy_min) quantity = buy_min;
        if (setItemToCart) {
            setItemToCart(collection_name, template_id, quantity);
        }
    }

    updateSupply = async () => {
        let { props: { item, template_id, price, match: { params: { collection_name } } } } = this;
        let priceSplit = price.split(" ");
        let priceToken = priceSplit[1];
        try {
            if (priceToken === "USD") {
                if (!this.state.max_supply) {
                    throw new Error('No max_supply');
                }
                if (this.state.inventory === false || this.state.supply > 0) {
                    let inventory = await fetch(global.pay_url + "/inventory?template_id=" + template_id).then(res => res.json());
                    if (!inventory) {
                        throw new Error('No inventory');
                    }
                    console.log(inventory);
                    let supply_display = parseInt(inventory.max_supply || 0) - parseInt(inventory.total || 0);
                    if (supply_display < 0) supply_display = 0;
                    this.setState({
                        loading: false,
                        inventory: true,
                        supply: parseInt(inventory.max_supply || 0) - parseInt(inventory.total || 0),
                        supply_display: supply_display,
                    }, () => {
                        setTimeout(this.updateSupply, 10000);
                    });
                }
            } else if (priceToken === "WAX") {
                // load reservations from smart contract
                let totalSupply = parseInt(item?.issued_supply);
                let premintCount = 0;
                try {
                    let premints = await ChainHelper.get_table_rows("premint.nft", "stats", collection_name, 1, template_id, template_id);
                    if (premints.rows?.length > 0) {
                        let premint = premints.rows[0];
                        if (premint.active) {
                            premintCount = premint.count;
                        }
                    }
                } catch (err3) {
                    console.error("failed to fetch premint in contract", err3);
                }
                totalSupply -= premintCount;
                let resCount = 0;
                try {
                    let res1 = await ChainHelper.get_table_rows("sales.nft", "salerezqty", collection_name, 100, template_id, template_id);
                    if (res1 && res1.rows) {
                        if (res1.rows.length === 1) {
                            resCount += res1.rows[0].total_reserved_quantity;
                        }
                    }
                } catch (err4) {
                    console.error("failed to fetch supply reserved in contract", err4);
                }
                totalSupply += resCount;
                let supply_display = parseInt(item?.max_supply) - parseInt(totalSupply);
                if (supply_display < 0) supply_display = 0;
                this.setState({
                    loading: false,
                    inventory: true,
                    supply: parseInt(item?.max_supply) - parseInt(totalSupply),
                    supply_display: supply_display,
                    max_supply: parseInt(item?.max_supply) - parseInt(resCount),
                }, () => {
                    setTimeout(this.updateSupply, 10000);
                });
            } else {
                this.setState({
                    loading: false,
                });
            }
        } catch (err1) {
            this.setState({
                loading: false,
            }, () => {
                setTimeout(this.updateSupply, 10000);
            });
        }
    }

    checkFreeClaim = async () => {
        let { props: { match: { params: { collection_name } } } } = this;
        let { template_id, price, userAccount, buy_max } = this.props;
        let [priceAmount, priceToken] = price.split(" ");

        let count = this.state.claimed;

        if (priceToken === "WAX" && buy_max > 0) {
            try {
                /* global BigInt */
                let name = this.nameToUint64(userAccount);
                let composite_key = BigInt((BigInt(template_id) << BigInt(64)) | BigInt(name)).toString();
                let salecount = await ChainHelper.get_table_rows("sales.nft", "sale.count", collection_name, 1, composite_key, composite_key, 2, "i128");
                if (salecount?.rows?.length > 0) {
                    count = salecount?.rows[0]?.count;
                }
            } catch (err) {
                console.error(err);
            }
        }

        this.setState({
            claimed: count
        }, () => {
            //setTimeout(this.checkFreeClaim, 10000);
        });
    }

    render() {
        let { item, price, buy_start, buy_end, buy_min, buy_max, quantity, reserved } = this.props;
        let { max_supply, supply, supply_display, inventory, claimed, isUserWhitelisted } = this.state;

        let [priceAmount, priceToken] = price.split(" ");
        let priceView = price;
        if (priceToken === "USD") {
            priceView = `$${priceAmount}`;
        } else if (priceToken === "WAX") {
            priceView = priceAmount > 0 ? `${parseFloat(priceAmount)} WAX` : `FREE`;
            price = priceView
        }

        if (quantity === 0) quantity = "";

        let timeDiff = buy_start - new Date().getTime();
        let timeDiffEnd = buy_end - new Date().getTime();
        let enabled = true && isUserWhitelisted;
        let reason = null;

        if (!isUserWhitelisted) {
            reason = "You are not whitelisted for this sale";
        }

        if (max_supply > 0 && Number.isInteger(quantity) && quantity > supply) {
            this.updateQuantity(0);
        }

        buy_end = parseInt(buy_end);
        if (buy_end > 0) {
            if (buy_end <= new Date().getTime() && quantity > 0) {
                this.updateQuantity(0)
            }
            if (buy_end <= new Date().getTime()) {
                enabled = false;
                reason = "Sale Ended";
            }
        }
        if (inventory !== true) {
            // If no response from inventory server, fall back to sale data
            supply = supply_display = supply + reserved;
            supply_display = max_supply - supply;
        }
        if (this.state.loading === true) {
            supply_display = ' ...';
        }
        if (max_supply > 0 && ((inventory === true && supply <= 0) || (inventory !== true && supply === max_supply))) {
            enabled = false;
            reason = "Sold Out";
        }
        if (enabled === true && buy_max > 0 && claimed >= buy_max) {
            enabled = false;
            reason = "Max Purchased";
        }

        return (
            <div className={"shop-item" + (enabled ? "" : " disabled")} data-reason={reason}>
                <div className="product">
                    <div className="preview">
                        <NFTPreview nft={item} size={"100x150"} />
                    </div>
                </div>
                <div className="info">
                    <div className="name">
                        <div>{item.name} { max_supply > 0 ? ( <small>({supply_display} / { max_supply } available)</small> ) : ( (buy_end === 0 || buy_end > new Date().getTime()) ? <small>(Unlimited)</small> : <small>&nbsp;</small>) }</div>
                    </div>
                    <div className="subname"><b>{price}</b>{item?.data?.contains && <> - <b>{item?.data?.contains}</b></>}{timeDiff > 0 && <> - <b>{API.msToTime(timeDiff)}</b></>}{timeDiff <= 0 && timeDiffEnd > 0 && <> - <b>Time Left: {API.msToTime(timeDiffEnd)}</b></>}</div>
                </div>
                <div className="quantity">
                    <div className="total">{priceView}</div>
                    {enabled &&
                        <>
                            <Button
                                primary
                                compact
                                circular
                                size="mini"
                                icon="minus"
                                onClick={() => this.updateQuantity(quantity - 1)}
                                disabled={quantity === ""}
                            />
                            <input
                                type="number"
                                disabled
                                placeholder={buy_min + (buy_max > 0 ? ` — ${buy_max}` : "")}
                                value={quantity}
                            />
                            <Button
                                primary
                                compact
                                circular
                                size="mini"
                                icon="plus"
                                onClick={() => this.updateQuantity(quantity + 1)}
                                disabled={
                                    (buy_max > 0 && quantity === buy_max) ||
                                    (buy_end > 0 && buy_end <= new Date().getTime())
                                }
                            />
                        </>
                    }
                    {!enabled && <span className="claimed">{reason}</span>}
                </div>
            </div>
        );
    }
}

export default withRouter(StoreListItem);
