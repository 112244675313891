const getCartName = (collection_name, store_type) => {
    //console.log(collection_name, store_type);
    return store_type + "_cart_" + collection_name;
}

const setItemToCart = (collection_name, template_id, quantity, store_type) => {
    console.log("setItemToCart", collection_name, store_type);
    let cart = [];
    if (localStorage.getItem(getCartName(collection_name, store_type))) cart = JSON.parse(localStorage.getItem(getCartName(collection_name, store_type)));
    
    let newCartItem = {template_id, quantity};

    let existingItems = cart.filter(cartItem => cartItem.template_id === template_id);
    let index = existingItems.length > 0 ? cart.indexOf(existingItems[0]) : -1;

    if (quantity > 0) {
        if (existingItems.length === 0) cart.push(newCartItem);
        else cart[index] = newCartItem;
    } else {
        if (existingItems.length > 0) {
            cart.splice(index, 1);
        }
    }

    localStorage.setItem(getCartName(collection_name, store_type), JSON.stringify(cart));
    return cart;
}

const getCart = (collection_name, store_type) => {
    console.log("getCart", collection_name, store_type);
    let cart = [];
    if (localStorage.getItem(getCartName(collection_name, store_type))) cart = JSON.parse(localStorage.getItem(getCartName(collection_name, store_type)));
    return cart;
}

const clearCart = (collection_name, store_type) => {
    console.log("clearCart", collection_name, store_type);
    localStorage.removeItem(getCartName(collection_name, store_type));
}

const CartAPI = {
    setItemToCart,
    getCart,
    clearCart
}

export default CartAPI;
