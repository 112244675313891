import React, { Component } from 'react';
import {Button, Grid, Message} from 'semantic-ui-react';
import ConfigLoader from 'helper/ConfigLoader';
import Loading from "components/Loading/Loading";
import PaymentIntentAPI from "helper/PaymentIntentAPI";
import PreviewVirl from "./PreviewVirl";
import CollectForm from "./CollectForm";
import {AppContext} from "provider/AppContext";
import VirlAPI from "../../helper/VirlAPI";
import {Redirect} from "react-router-dom";

const endedStatus = [4];

class CollectVirl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            asset: null,
            type: null,
            stripe_id: null,
            client_secret: null,
            intent: null,
            redirect: null,
        };
    }

    componentDidMount() {
        let { collection_name, asset_id } = this.props;

        ConfigLoader.loadVirl(collection_name, asset_id).then(asset => {
            this.setState({
                loading: false,
                asset: asset.asset,
                error: asset.error || null
            });
        }).catch(err => {
            this.setState({
                loading: false,
                error: err.toString()
            });
        }).finally(async () => {
            try {
                await this.loadPaymentIntent();
            } catch (e) {
                console.error(e.message);
            }
            await VirlAPI.getStatus([asset_id]).then(async (status) => {
                if (Object.keys(status).length > 0 && status[asset_id]?.error) {
                    this.setState({
                        loading: false,
                        error: status[asset_id]?.error,
                    });
                    return;
                }
                if (Object.keys(status).length === 0 || parseInt(status[asset_id]?.status) < VirlAPI.RedemptionState.PAID) {
                    if ([null, "stripe"].includes(this.state.type)) {
                        await this.setState({
                            redirect: <Redirect to={"/" + collection_name + "/redeem/" + asset_id}/>,
                        });
                    }
                }
                if (Object.keys(status).length > 0 && status[asset_id]) {
                    this.setState({
                        loading: false,
                        type: status[asset_id]?.type || this.state.type,
                        intent: status[asset_id]?.intent || this.state.intent,
                    });
                }
            });
        });
    }

    loadPaymentIntent = async () => {
        let paymentIntent = await PaymentIntentAPI.getRecentPaymentIntent("virl");
        console.log(paymentIntent);
        if (!paymentIntent) {
            this.setState({
                loading: false
            });
            return;
        }
        console.log(paymentIntent?.metadata);
        if (paymentIntent?.type === "free") {
            await this.setState({
                loading: false,
                type: "free",
                intent: paymentIntent,
            });
        } else {
            await this.setState({
                loading: false,
                type: "stripe",
                intent: {stripe_id: paymentIntent.id},
                stripe_id: paymentIntent.id,
                client_secret: paymentIntent.client_secret,
            });
            await this.updatePaymentIntent();
        }
    }

    updatePaymentIntent = async () => {
        if (this.state.stripe_id && this.state.client_secret) {
            let intent = await PaymentIntentAPI.getUpdatedPaymentIntent(this.state.stripe_id, this.state.client_secret);
            this.setState({
                loading: false,
                intent: intent,
            }, () => {
                if (endedStatus.includes(intent?.captured)) return;
                if (intent?.captured === 2) return;
                setTimeout(this.updatePaymentIntent, 5000);
            });
        } else {
            setTimeout(this.updatePaymentIntent, 5000);
        }
    }

    setError = (message) => {
        message = message.replace("assertion failure with message: ", "");
        this.setState({
            error: message,
        });
    }

    render() {
        let { wax } = this.context;

        if (this.state.redirect) {
            return this.state.redirect;
        }

        if (!this.state.loading && this.state.error) {
            return (
                <Message negative>
                    <p>{this.state.error}</p>
                </Message>
            );
        }

        let {intent} = this.state;
        let success = false;
        let title = "";
        let memo = (
            <>
                <div style={{textAlign: "center"}}>
                    <p>We've received your order and are processing your payment. This may take a few moments...</p>
                    <Loading />
                    <p>We still need to collect your vIRL in the next step to complete the process, so hang tight!</p>
                </div>
            </>
        );

        if (this.state.type === "stripe") {
            switch (intent?.captured) {
                case 4:
                    title = "Order Canceled";
                    memo = (
                        <>
                            We're sorry to inform you that your payment was canceled, funds have not been withdrawn from
                            you account. If you have pending charges your bank can confirm that the charge will be
                            removed within the next 7-21 business days.
                        </>
                    );
                    break;
                case 2:
                    success = true;
                    title = "Payment Successful!";
                    memo = (
                        <>
                            We've received your payment but we still need to collect your vIRL to complete the process.
                        </>
                    );
                    break;
                default:
            }
        }

        return (
            <main id="CollectVirl">
                {this.state.loading &&
                    <Loading />
                }
                {!this.state.loading && this.state.type === "stripe" && !intent &&
                    <Message negative>
                        <p>Error: Payment not found</p>
                    </Message>
                }
                {!this.state.loading &&
                    <Grid reversed='mobile vertically'>
                        <Grid.Column className="virl-redemption" mobile={16} tablet={8} computer={8}>
                            <h2>Redeem Physical Goods</h2>
                            {!wax.userAccount &&
                                <div className="logged-out">
                                    <div className="section-heading">
                                        Please login to continue with redemption.
                                    </div>
                                    <Button as={'a'} primary compact href={global.pay_url + '/login'} referrerpolicy={'unsafe-url'}>Login</Button>
                                </div>
                            }
                            {wax.userAccount &&
                                <>
                                    {this.state.type === "stripe" && intent &&
                                        <section className="order-status">
                                            {title &&
                                            <h2 className={success ? "success" : ""}>{title}</h2>
                                            }
                                            <p>{memo}</p>
                                        </section>
                                    }
                                    {(
                                        (this.state.type === "stripe" && intent && intent?.captured === 2)
                                        ||
                                        this.state.type === "free"
                                    ) &&
                                        <CollectForm
                                            {...this.props}
                                            error={this.state.error}
                                            setError={this.setError}
                                            metadata={intent?.metadata}
                                        />
                                    }
                                </>
                            }
                        </Grid.Column>
                        <PreviewVirl asset={this.state.asset} />
                    </Grid>
                }
            </main>
        )
    }
}

CollectVirl.contextType = AppContext;

export default CollectVirl;
