import React from 'react';
import {Grid} from 'semantic-ui-react';
import NFTPreview from "components/NFTPreview/NFTPreview";

const PreviewVirl = (props) => {
    let asset = props.asset;

    return (
        <Grid.Column className="virl-preview" textAlign="center" mobile={16} tablet={8} computer={8}>
            <NFTPreview nft={asset} size={"260x260"} />
            <div className="virl-details">
                <div className="virl-name">
                    {asset?.name}
                </div>
                <div className="virl-info">
                    {asset?.data?.rarity &&
                        <>{asset?.data?.rarity} - </>
                    }
                    {asset.asset_id}
                </div>
                <div className="virl-restriction">
                    {asset?.virl?.restrictions}
                </div>
            </div>
        </Grid.Column>
    )
}

export default PreviewVirl;
