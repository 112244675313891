import React, { Component } from 'react';
import { AppContext } from './AppContext';
import * as waxjs from "@waxio/waxjs/dist";

export class AppProvider extends Component {
    static displayName = 'AppProvider'

    constructor(props) {
        super(props);

        this.state = {
            wax: this.initWaxJs()
        }
    }

    componentDidMount() {
        fetch(global.pay_url + "/session", {
            credentials: 'include'
        })
        .then(async res => {
            console.log(res);
            if (res.status === 200) {
                let account = await res.json();
                this.setState({
                    wax: this.initWaxJs(account.accountName, account.publicKeys)
                })
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

    initWaxJs(userAccount = undefined, pubKeys = undefined) {
        return new waxjs.WaxJS({
            rpcEndpoint: process.env.REACT_APP_WAX_CHAIN_URL || "https://chain.wax.io",
            waxSigningURL: process.env.REACT_APP_WAX_SIGNING_URL || undefined,
            waxAutoSigningURL: process.env.REACT_APP_WAX_AUTOSIGNING_URL || undefined,
            tryAutoLogin: false,
            userAccount: userAccount,
            pubKeys: pubKeys,
        });
    }

    render() {
        return (
            <AppContext.Provider value={this.state}>
                { this.props.children }
            </AppContext.Provider>
        )
    }
}

AppProvider.propTypes = {

}

AppProvider.defaultProps = {

}
